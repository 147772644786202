var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { Typography, Stack } from '@mui/material';
import { EmptyContentIllustration } from '../../assets/illustrations';
//
import Image from '../image';
export default function EmptyContent(_a) {
    var title = _a.title, description = _a.description, img = _a.img, sx = _a.sx, other = __rest(_a, ["title", "description", "img", "sx"]);
    return (_jsxs(Stack, __assign({ alignItems: "center", justifyContent: "center", sx: __assign({ height: 1, textAlign: 'center', p: function (theme) { return theme.spacing(8, 2); } }, sx) }, other, { children: [_jsx(Image, { disabledEffect: true, alt: "empty content", src: img || EmptyContentIllustration, sx: { height: 240, mb: 3 } }), _jsx(Typography, __assign({ variant: "h5", gutterBottom: true }, { children: title })), description && (_jsx(Typography, __assign({ variant: "body2", sx: { color: 'text.secondary' } }, { children: description })))] })));
}
